import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import { EmptyState } from '~/components/EmptyState';
import Layout from '~/components/Layout';
import { ParseError } from '~/errors';
import appCrashedImage from '~/shared/assets/images/app_crashed.png';
import appCrashedImage2x from '~/shared/assets/images/app_crashed@2x.png';
import { useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export default function GenericErrorPage() {
    const error = useRouteError();
    if (error instanceof ParseError) {
        return React.createElement(ParseErrorPage, null);
    }
    return (React.createElement(Layout, { rootBackgroundColor: "#EFEFEF" },
        React.createElement(GenericErrorPageContent, null)));
}
function ParseErrorPage() {
    return (React.createElement(Layout, { rootBackgroundColor: "#EFEFEF" },
        React.createElement(Root, null,
            React.createElement(EmptyState, { image: React.createElement("img", { src: appCrashedImage, srcSet: `${appCrashedImage2x} 2x`, alt: "App crashed" }), link: React.createElement(Button, { kind: "special", as: Link, to: "https://streamr.network/", className: "d-none d-md-flex" }, "Go to public site"), linkOnMobile: true },
                React.createElement("p", null, "The Hub is temporarily unavailable."),
                React.createElement(SecondaryP, null,
                    "Our connection to the Subgraph indexing the Streamr Smart Contracts",
                    React.createElement("br", null),
                    "is currently unavailable. Please try again in a few minutes.")))));
}
export function GenericErrorPageContent() {
    const chainKey = useCurrentChainKey();
    return (React.createElement(Root, null,
        React.createElement(EmptyState, { image: React.createElement("img", { src: appCrashedImage, srcSet: `${appCrashedImage2x} 2x`, alt: "App crashed" }), link: React.createElement(Button, { kind: "special", as: Link, to: R.projects(routeOptions(chainKey)), className: "d-none d-md-flex" }, "Projects"), linkOnMobile: true },
            React.createElement("p", null,
                "Oops. Something has broken down here.",
                React.createElement("br", null),
                "Please try one of the links below",
                React.createElement("br", null),
                "to get things back on track."))));
}
const SecondaryP = styled.p.withConfig({ displayName: "SecondaryP", componentId: "sc-da52xt" }) `
    font-size: 0.8em;
    margin-top: 1em;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-28tayp" }) `
    text-align: center;
    width: 100%;

    img {
        margin-bottom: -0.75em;
        max-width: 180px;
    }

    @media (min-width: 376px) {
        img {
            max-width: 200px;
        }
    }

    @media (min-width: 745px) {
        img {
            max-width: 40%;
        }
    }

    @media (min-width: 1441px) {
        img {
            max-width: 50%;
        }
    }
`;
