import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { LoadMoreButton } from '~/components/LoadMore';
import { StreamIdCell } from '~/components/Table';
import { Minute } from '~/consts';
import { getStreamsFromIndexer, isIndexerColumn, } from '~/hooks/streams';
import { ScrollTableCore } from '~/shared/components/ScrollTable/ScrollTable';
import { useCurrentChainId, useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export function QueriedStreamsTable({ noDataFirstLine, noDataSecondLine, onOrderChange, orderBy = 'mps', orderDirection, query, }) {
    const pages = query.data?.pages || [];
    const streams = pages.flatMap((d) => d.streams) || [];
    const [streamStats, setStreamStats] = useState({});
    const chainId = useCurrentChainId();
    const chainKey = useCurrentChainKey();
    const indexerQueryErrored = query.isError && isIndexerColumn(chainId, orderBy);
    const onOrderChangeRef = useRef(onOrderChange);
    if (onOrderChangeRef.current !== onOrderChange) {
        onOrderChangeRef.current = onOrderChange;
    }
    useEffect(function fallbackToGraphOnIndexerError() {
        if (!indexerQueryErrored) {
            return;
        }
        onOrderChangeRef.current?.('id', 'asc');
    }, [indexerQueryErrored]);
    return (React.createElement(React.Fragment, null,
        pages.map((page) => (React.createElement(StreamStatsLoader, { key: page.pageId, page: page, onStats: (stats) => {
                setStreamStats((current) => ({
                    ...current,
                    ...stats,
                }));
            } }))),
        React.createElement(ScrollTableCore, { noDataFirstLine: noDataFirstLine, noDataSecondLine: noDataSecondLine, elements: streams, orderBy: orderBy, orderDirection: orderDirection, onOrderChange: (orderBy) => {
                onOrderChange?.(orderBy);
            }, isLoading: query.isLoading || query.isFetching || query.isFetchingNextPage, columns: [
                {
                    key: 'id',
                    displayName: 'Stream ID',
                    isSticky: true,
                    sortable: true,
                    valueMapper: ({ id, description }) => (React.createElement(StreamIdCell, { streamId: id, description: description || '' })),
                },
                {
                    key: 'peerCount',
                    displayName: 'Nodes',
                    sortable: true,
                    valueMapper: ({ id, peerCount = streamStats[id]?.peerCount }) => peerCount ?? '-',
                },
                {
                    key: 'mps',
                    displayName: 'Msg/s',
                    sortable: true,
                    valueMapper: ({ id, messagesPerSecond: mps = streamStats[id]?.messagesPerSecond, }) => mps ?? '-',
                },
                {
                    key: 'bps',
                    displayName: 'KB/s',
                    sortable: true,
                    valueMapper: ({ id, bytesPerSecond: bps = streamStats[id]?.bytesPerSecond, }) => (bps == null ? '-' : (bps / 1024).toFixed(2)),
                },
                {
                    key: 'access',
                    displayName: 'Access',
                    valueMapper: ({ subscriberCount }) => subscriberCount == null ? 'Public' : 'Private',
                },
                {
                    key: 'publishers',
                    displayName: 'Publishers',
                    valueMapper: ({ publisherCount = '∞' }) => publisherCount,
                },
                {
                    key: 'subscribers',
                    displayName: 'Subscribers',
                    valueMapper: ({ subscriberCount = '∞' }) => subscriberCount,
                },
            ], linkMapper: (element) => R.stream(element.id, routeOptions(chainKey)) }),
        query.hasNextPage && (React.createElement(LoadMoreButton, { disabled: query.isLoading || query.isFetching, onClick: () => {
                query.fetchNextPage();
            }, kind: "primary2" }, "Load more"))));
}
function StreamStatsLoader({ onStats, page }) {
    const chainId = useCurrentChainId();
    const streamIdsRef = useRef(page.streams.map(({ id }) => id));
    const { data: result } = useQuery({
        queryKey: ['StreamStatsLoader.statsQuery', chainId, page.pageId, page.source],
        queryFn: async () => {
            if (page.source === 'indexer') {
                return {};
            }
            try {
                const { streams } = await getStreamsFromIndexer(chainId, {
                    force: true,
                    pageSize: Math.min(1000, streamIdsRef.current.length),
                    streamIds: streamIdsRef.current,
                });
                const stats = {};
                for (const { bytesPerSecond, id, messagesPerSecond, peerCount, } of streams) {
                    stats[id] = {
                        bytesPerSecond,
                        messagesPerSecond,
                        peerCount,
                    };
                }
                return stats;
            }
            catch (_) {
                // ¯\_(ツ)_/¯
            }
            return {};
        },
        staleTime: 5 * Minute,
    });
    const onStatsRef = useRef(onStats);
    if (onStatsRef.current !== onStats) {
        onStatsRef.current = onStats;
    }
    useEffect(function triggerOnStatsOnNonFalsyResult() {
        if (result) {
            onStatsRef.current?.(result);
        }
    }, [result]);
    return React.createElement(React.Fragment, null);
}
