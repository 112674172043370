import { config as configs } from '@streamr/config';
import { produce } from 'immer';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { defaultChainKey } from '~/consts';
import { fallbackChainConfigExtension, parsedChainConfigExtension, } from '~/utils/chainConfigExtension';
import formatConfigUrl from './formatConfigUrl';
const lowerCasedChainKeyToChainKeyMap = {};
/**
 * @param candidate Chain key or chain slug (from config extension) or chain number. Defaults
 * to the default chain key (currently 'polygon').
 */
export function getChainKey(candidate) {
    const key = typeof candidate === 'number' ? candidate : candidate.toLowerCase();
    if (lowerCasedChainKeyToChainKeyMap[key] === null) {
        return defaultChainKey;
    }
    if (lowerCasedChainKeyToChainKeyMap[key]) {
        return lowerCasedChainKeyToChainKeyMap[key];
    }
    for (const chainKey in configs) {
        if (!isChainKey(chainKey)) {
            continue;
        }
        if (typeof key === 'number') {
            if (configs[chainKey].id === key) {
                lowerCasedChainKeyToChainKeyMap[key] = chainKey;
                return chainKey;
            }
            continue;
        }
        if (chainKey.toLowerCase() === key) {
            lowerCasedChainKeyToChainKeyMap[key] = chainKey;
            return chainKey;
        }
        const slug = parsedChainConfigExtension[chainKey]?.slug?.toLowerCase();
        if (key === slug) {
            lowerCasedChainKeyToChainKeyMap[key] = chainKey;
            return chainKey;
        }
    }
    console.warn(`Could not find a proper chain key for "${candidate}". Using default key (${defaultChainKey}).`);
    lowerCasedChainKeyToChainKeyMap[key] = null;
    return defaultChainKey;
}
export function getCurrentChain() {
    return getChainConfig(new URLSearchParams(window.location.search).get('chain') || defaultChainKey);
}
export function getCurrentChainId() {
    return getCurrentChain().id;
}
export function useCurrentChain() {
    const chainName = useSearchParams()[0].get('chain') || defaultChainKey;
    return useMemo(() => getChainConfig(chainName), [chainName]);
}
export function useCurrentChainId() {
    return useCurrentChain().id;
}
export function useCurrentChainKey() {
    return getChainKey(useCurrentChainId());
}
/**
 * @todo rename to `useCurrentFullChainName`.
 */
export function useCurrentChainFullName() {
    return getChainDisplayName(useCurrentChainId());
}
const chainKeyToChainEntryMap = {};
function getChainEntry(chainKey) {
    if (chainKeyToChainEntryMap[chainKey]) {
        return chainKeyToChainEntryMap[chainKey];
    }
    const config = configs[chainKey];
    const configExtension = parsedChainConfigExtension[chainKey] || fallbackChainConfigExtension;
    const { dockerHost } = configExtension;
    const sanitizedConfig = produce(config, (draft) => {
        for (const rpc of draft.rpcEndpoints) {
            rpc.url = formatConfigUrl(rpc.url, {
                dockerHost,
            });
        }
        if (draft.entryPoints) {
            for (const entrypoint of draft.entryPoints) {
                entrypoint.websocket.host = formatConfigUrl(entrypoint.websocket.host, {
                    dockerHost,
                });
            }
        }
        if (draft.theGraphUrl) {
            draft.theGraphUrl = formatConfigUrl(draft.theGraphUrl, { dockerHost });
        }
    });
    const entry = {
        chainKey,
        config: sanitizedConfig,
        configExtension,
    };
    chainKeyToChainEntryMap[chainKey] = entry;
    return entry;
}
export function getChainConfig(chainIdOrChainKey) {
    return getChainEntry(getChainKey(chainIdOrChainKey)).config;
}
export function getChainConfigExtension(chainId) {
    return getChainEntry(getChainKey(chainId)).configExtension;
}
export function getChainSlug(chainIdOrChainKey) {
    const chainEntry = getChainEntry(getChainKey(chainIdOrChainKey));
    return chainEntry.configExtension.slug || chainEntry.chainKey;
}
/**
 * Checks if a given string is a `ChainKey`.
 * @param candidate Any string.
 * @returns `true` if the given string is config's own key.
 */
export function isChainKey(candidate) {
    return Object.prototype.hasOwnProperty.call(configs, candidate);
}
export function isKnownChainId(candidate) {
    return Object.entries(configs).some(([, { id }]) => id === candidate);
}
export function getChainDisplayName(chainIdOrChainKey) {
    const { config, configExtension } = getChainEntry(getChainKey(chainIdOrChainKey));
    return configExtension.displayName || config.name;
}
export function getMarketplaceChainConfigs(chainIdOrChainKey) {
    const marketplaceChainKeys = getChainEntry(getChainKey(chainIdOrChainKey))
        .configExtension.marketplaceChains;
    const result = [];
    for (const key of marketplaceChainKeys) {
        if (isChainKey(key)) {
            result.push(getChainConfig(key));
        }
    }
    return result;
}
export function getCoingeckoNetworkId(chainIdOrChainKey) {
    const { config, configExtension } = getChainEntry(getChainKey(chainIdOrChainKey));
    return configExtension.coingeckoNetworkId || config.name;
}
