import React from 'react';
import { Decimals, SponsorshipDecimals } from '~/components/Decimals';
import { LoadMoreButton } from '~/components/LoadMore';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { FundedUntilCell, NumberOfOperatorsCell, SponsorshipApyCell, StreamIdCell, } from '~/components/Table';
import { useOperatorForWalletQuery } from '~/hooks/operators';
import { useEditSponsorshipFunding, useFundSponsorshipCallback, useJoinSponsorshipAsOperator, useSponsorshipTokenInfo, } from '~/hooks/sponsorships';
import { ScrollTableCore } from '~/shared/components/ScrollTable/ScrollTable';
import { useWalletAccount } from '~/shared/stores/wallet';
import { useCurrentChainId, useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { isSponsorshipFundedByOperator } from '~/utils/sponsorships';
export function QueriedSponsorshipsTable({ noDataFirstLine = 'No data', noDataSecondLine, orderBy, orderDirection, onOrderChange, query, hideStreamId = false, }) {
    const sponsorships = query.data?.pages.map((page) => page.sponsorships).flat() || [];
    const wallet = useWalletAccount();
    const { data: operator = null } = useOperatorForWalletQuery(wallet);
    const chainId = useCurrentChainId();
    const chainKey = useCurrentChainKey();
    const fundSponsorship = useFundSponsorshipCallback();
    const joinSponsorshipAsOperator = useJoinSponsorshipAsOperator();
    const editSponsorshipFunding = useEditSponsorshipFunding();
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollTableCore, { elements: sponsorships, isLoading: query.isLoading || query.isFetching || query.isFetchingNextPage, orderBy: orderBy, orderDirection: orderDirection, onOrderChange: onOrderChange, columns: [
                ...(hideStreamId == false
                    ? [
                        {
                            displayName: 'Stream ID',
                            valueMapper: ({ streamId }) => (React.createElement(StreamIdCell, { streamId: streamId })),
                            align: 'start',
                            isSticky: true,
                            key: 'streamInfo',
                            sortable: true,
                        },
                    ]
                    : []),
                {
                    displayName: (React.createElement(React.Fragment, null,
                        React.createElement(SponsorshipPaymentTokenName, null),
                        "/day")),
                    valueMapper: (element) => (React.createElement(Decimals, { abbr: true, amount: element.payoutPerDay, decimals: decimals })),
                    align: 'start',
                    isSticky: hideStreamId,
                    key: 'payoutPerDay',
                    sortable: true,
                },
                {
                    displayName: 'Funds',
                    valueMapper: (element) => (React.createElement(Decimals, { abbr: true, amount: element.timeCorrectedRemainingBalanceWeiAt(Date.now()), decimals: decimals })),
                    align: 'start',
                    isSticky: false,
                    key: 'remainingWei',
                    sortable: true,
                },
                {
                    displayName: 'Operators',
                    valueMapper: (element) => (React.createElement(NumberOfOperatorsCell, { sponsorship: element, currentOperatorId: operator?.id })),
                    align: 'start',
                    isSticky: false,
                    key: 'operators',
                    sortable: true,
                },
                {
                    displayName: 'Staked',
                    valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.totalStakedWei })),
                    align: 'end',
                    isSticky: false,
                    key: 'staked',
                    sortable: true,
                },
                {
                    displayName: 'APY',
                    valueMapper: (element) => (React.createElement(SponsorshipApyCell, { spotAPY: element.spotApy, isRunning: element.isRunning })),
                    align: 'end',
                    isSticky: false,
                    key: 'apy',
                    sortable: true,
                },
                {
                    displayName: 'Funded until',
                    valueMapper: (element) => (React.createElement(FundedUntilCell, { projectedInsolvencyAt: element.projectedInsolvencyAt, remainingBalance: element.remainingBalanceWei })),
                    align: 'start',
                    isSticky: false,
                    key: 'fundedUntil',
                    sortable: true,
                },
            ], actions: [
                {
                    displayName: 'Sponsor',
                    disabled: ({ streamId }) => !streamId,
                    callback(element) {
                        fundSponsorship(chainId, element);
                    },
                },
                (element) => {
                    if (isSponsorshipFundedByOperator(element, operator)) {
                        return {
                            displayName: 'Edit stake',
                            callback() {
                                if (!operator) {
                                    return;
                                }
                                editSponsorshipFunding({
                                    chainId,
                                    sponsorshipOrSponsorshipId: element,
                                    operator,
                                });
                            },
                        };
                    }
                    const maxOperatorsReached = element.operatorCount >= element.maxOperators;
                    return {
                        displayName: 'Join as Operator',
                        disabled: !element.streamId || !operator || maxOperatorsReached,
                        callback() {
                            if (!operator) {
                                return;
                            }
                            joinSponsorshipAsOperator({
                                chainId,
                                sponsorship: element,
                                operator,
                            });
                        },
                    };
                },
            ], noDataFirstLine: noDataFirstLine, noDataSecondLine: noDataSecondLine, linkMapper: (element) => R.sponsorship(element.id, routeOptions(chainKey)) }),
        query.hasNextPage && (React.createElement(LoadMoreButton, { disabled: query.isLoading || query.isFetching, onClick: () => void query.fetchNextPage(), kind: "primary2" }, "Load more"))));
}
