import InfoIcon from '@atlaskit/icon/glyph/info';
import React from 'react';
import { Link as PrestyledLink } from 'react-router-dom';
import styled from 'styled-components';
import { Address, AddressContent, AddressLabel, Banner, IconWrap, } from '~/components/ActionBars/AboutEntity';
import { DefaultSimpleDropdownMenu } from '~/components/SimpleDropdown';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { ExternalLinkIcon } from '~/icons';
import { truncateStreamName } from '~/shared/utils/text';
import { useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export function AboutSponsorship({ sponsorship }) {
    const { streamId } = sponsorship;
    const chainKey = useCurrentChainKey();
    return (React.createElement(DefaultSimpleDropdownMenu, null,
        streamId && (React.createElement(Address, null,
            React.createElement(AddressLabel, null, "Sponsored stream:"),
            React.createElement(AddressContent, null,
                React.createElement("strong", null, truncateStreamName(streamId)),
                React.createElement("div", null,
                    React.createElement(Link, { to: R.stream(streamId, routeOptions(chainKey)), target: "_blank" },
                        React.createElement(ExternalLinkIcon, null)))))),
        React.createElement(Banner, null,
            React.createElement(IconWrap, null,
                React.createElement(InfoIcon, { label: "Info" })),
            React.createElement("div", null,
                React.createElement("p", null,
                    "Sponsorships payout ",
                    React.createElement(SponsorshipPaymentTokenName, null),
                    " tokens to staked Operators that relay data in the sponsored stream. Anyone can fund or extend a Sponsorship."),
                React.createElement("p", null,
                    React.createElement("a", { href: R.docs('/streamr-network/network-incentives'), target: "_blank", rel: "noreferrer noopener" }, "Learn more"))))));
}
const Link = styled(PrestyledLink).withConfig({ displayName: "Link", componentId: "sc-ri9tjx" }) `
    display: block;

    :active {
        transform: translateY(1px);
    }
`;
