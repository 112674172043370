import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import { address0 } from '~/consts';
import { getAllowance } from '~/getters';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import NetworkIcon from '~/shared/components/NetworkIcon';
import TokenLogo from '~/shared/components/TokenLogo';
import Text from '~/shared/components/Ui/Text';
import useIsMounted from '~/shared/hooks/useIsMounted';
import { COLORS, LIGHT, MEDIUM } from '~/shared/utils/styled';
import { timeUnits } from '~/shared/utils/timeUnit';
import { toFloat } from '~/utils/bn';
import { getChainDisplayName } from '~/utils/chains';
import { RejectionReason } from '~/utils/exceptions';
import { convertPrice } from '~/utils/price';
import ProjectModal, { Actions } from './ProjectModal';
const options = [timeUnits.hour, timeUnits.day, timeUnits.week, timeUnits.month].map((unit) => ({
    label: `${unit.charAt(0).toUpperCase()}${unit.slice(1)}s`, // Uppercase first letter and pluralize
    value: unit,
}));
const PeriodContainer = styled.div.withConfig({ displayName: "PeriodContainer", componentId: "sc-19jqspw" }) `
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #ffffff;
    border-radius: 8px;
    gap: 16px;
    padding: 32px 24px;
    position: relative;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const DetailsContainer = styled.div.withConfig({ displayName: "DetailsContainer", componentId: "sc-1pwdhu3" }) `
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    margin-top: 12px;
`;
const Chain = styled.div.withConfig({ displayName: "Chain", componentId: "sc-nek5xb" }) `
    display: grid;
    grid-template-columns: auto 1fr;
    background: ${COLORS.secondary};
    border-radius: 8px;
    padding: 18px 16px;
    gap: 8px;
    align-items: center;
`;
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-aylxsz" }) `
    width: 32px;
    height: 32px;
`;
const ChainName = styled.div.withConfig({ displayName: "ChainName", componentId: "sc-qg32fm" }) `
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;
const Amount = styled.span.withConfig({ displayName: "Amount", componentId: "sc-1bsh4vj" }) `
    font-size: 44px;
    font-weight: ${LIGHT};
`;
const AmountBoxInner = styled.div.withConfig({ displayName: "AmountBoxInner", componentId: "sc-8iawiw" }) `
    padding: 0 16px;
    width: max-content;
`;
const AmountBox = styled.div.withConfig({ displayName: "AmountBox", componentId: "sc-1caxpmp" }) `
    background: #f5f5f5;
    border-radius: 8px;
    position: relative;
    margin-top: 12px;
    line-height: normal;
`;
const AmountScrollable = styled.div.withConfig({ displayName: "AmountScrollable", componentId: "sc-1ki28es" }) `
    max-width: 100%;
    overflow: auto;
    padding: 44px 0;
`;
const AmountBarInner = styled.div.withConfig({ displayName: "AmountBarInner", componentId: "sc-z4fxmg" }) `
    padding: 0 16px;

    > div {
        height: 19px;
        display: flex;
        align-items: center;
    }

    > div > div {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    > div > * + div {
        margin-left: 12px;
    }

    > div > span {
        flex-grow: 1;
    }
`;
const AmountBar = styled.div.withConfig({ displayName: "AmountBar", componentId: "sc-3uap05" }) `
    letter-spacing: 0.05em;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    color: #525252;
    text-transform: uppercase;
    position: absolute;
    padding: 18px 0;
    pointer-events: none;

    * + & {
        color: #a3a3a3;
        top: 90px;
    }

    em {
        background: white;
        padding: 2px 4px;
        border-radius: 2px;
        font-style: normal;
        margin-right: 0.25em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const Currency = styled.span.withConfig({ displayName: "Currency", componentId: "sc-dpb4da" }) `
    font-weight: ${MEDIUM};
    font-size: 12px;
    line-height: 24px;
    margin-left: 8px;
`;
export default function AccessPeriodModal({ account = address0, backable = false, balance = 0n, chainId = 137, onReject, onResolve, pricePerSecond = 0n, quantity = 1, tokenAddress = address0, tokenDecimals = 18n, tokenSymbol = 'DATA', unit = timeUnits.hour, usdRate = 1, }) {
    const [rawLength, setRawLength] = useState(`${quantity}`);
    useEffect(function updateRawLength() {
        setRawLength(`${quantity}`);
    }, [quantity]);
    const length = Math.max(0, Number(rawLength) || 0);
    const [selectedUnit, setSelectedUnit] = useState(unit);
    useEffect(() => {
        setSelectedUnit(unit);
    }, [unit]);
    const total = ((a) => (a > 0n ? a : 0n))(convertPrice(pricePerSecond, [length, selectedUnit]));
    const disabled = total === 0n;
    const totalInUSD = toFloat(total, tokenDecimals).multipliedBy(usdRate);
    const [isSubmitting, setIsSubmitting] = useState(false);
    /**
     * It's safe to use `useIsMounted` here because even if we rerender the component
     * for a different purchase it'll be disabled if there's one already being processed.
     */
    const isMounted = useIsMounted();
    return (React.createElement(ProjectModal, { backable: backable, closeOnEscape: true, onReject: onReject, title: "Choose access period" },
        React.createElement("form", { onSubmit: async (e) => {
                e.preventDefault();
                if (disabled || isSubmitting) {
                    return;
                }
                try {
                    setIsSubmitting(true);
                    if (!length) {
                        throw new Error('Invalid quantity');
                    }
                    const allowance = await getAllowance(chainId, tokenAddress, account, {
                        recover: true,
                    });
                    onResolve?.({
                        quantity: length,
                        unit: selectedUnit,
                        exceedsAllowance: allowance < total,
                    });
                }
                catch (e) {
                    onReject?.(e);
                }
                finally {
                    if (isMounted()) {
                        setIsSubmitting(false);
                    }
                }
            } },
            React.createElement(PeriodContainer, null,
                React.createElement(Text, { value: rawLength, onChange: (e) => {
                        setRawLength(e.target.value);
                    }, onCommit: setRawLength, autoFocus: true, name: "quantity", disabled: isSubmitting }),
                React.createElement(SelectField2, { placeholder: "Select a time unit", options: options, value: selectedUnit, onChange: setSelectedUnit, isClearable: false, fullWidth: true, disabled: isSubmitting })),
            React.createElement(DetailsContainer, null,
                React.createElement(Chain, null,
                    React.createElement(ChainIcon, { chainId: chainId }),
                    React.createElement(ChainName, null, getChainDisplayName(chainId))),
                React.createElement(AmountBox, null,
                    React.createElement(AmountBar, null,
                        React.createElement(AmountBarInner, null,
                            React.createElement("div", null,
                                React.createElement("em", null, toFloat(balance, tokenDecimals).toFixed(3)),
                                ' ',
                                React.createElement("span", null, "Balance"),
                                React.createElement("div", null,
                                    React.createElement(TokenLogo, { chainId: chainId, contractAddress: tokenAddress, symbol: tokenSymbol }))))),
                    React.createElement(AmountScrollable, null,
                        React.createElement(AmountBoxInner, null,
                            React.createElement(Amount, null, toFloat(total, tokenDecimals).toFixed(3)),
                            React.createElement(Currency, null, tokenSymbol))),
                    React.createElement(AmountBar, null,
                        React.createElement(AmountBarInner, null,
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    "Approx ",
                                    totalInUSD.toFixed(2),
                                    " USD")))))),
            React.createElement(Actions, null,
                !backable && (React.createElement(Button, { kind: "link", type: "button", onClick: () => {
                        onReject?.(RejectionReason.CancelButton);
                    } }, "Cancel")),
                React.createElement(Button, { type: "submit", disabled: disabled || isSubmitting, waiting: isSubmitting }, "Pay now")))));
}
