import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import { EmptyState } from '~/components/EmptyState';
import Layout from '~/components/Layout';
import pageNotFoundPic from '~/shared/assets/images/404_blocks.png';
import pageNotFoundPic2x from '~/shared/assets/images/404_blocks@2x.png';
import { useCurrentChainFullName, useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export default function NotFoundPage() {
    return (React.createElement(Layout, { rootBackgroundColor: "#EFEFEF" },
        React.createElement(NotFoundPageContent, null)));
}
export function NotFoundPageContent() {
    const chainKey = useCurrentChainKey();
    const fullChainName = useCurrentChainFullName();
    return (React.createElement(Root, null,
        React.createElement(EmptyState, { image: React.createElement("img", { src: pageNotFoundPic, srcSet: `${pageNotFoundPic2x} 2x`, alt: "Not found" }), link: React.createElement(React.Fragment, null,
                React.createElement(Button, { kind: "special", as: Link, to: R.streams(routeOptions(chainKey)) }, "Go to streams"),
                React.createElement(Button, { kind: "special", as: Link, to: R.projects(routeOptions(chainKey)) }, "Go to projects"),
                React.createElement(Button, { kind: "special", as: Link, to: R.root() }, "Go to public site")), linkOnMobile: true },
            React.createElement("p", null,
                "Whoops! We don't seem to be able to find your data.",
                React.createElement("br", null),
                React.createElement("small", null,
                    "You are on the ",
                    fullChainName,
                    " chain. Are you on the right chain?")))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-xrbw83" }) `
    text-align: center;
    width: 100%;
`;
