module.exports    = {
	"ethereum": {
		"displayName": "Ethereum Mainnet"
	},
	"binance": {
		"coingeckoNetworkId": "binance-smart-chain",
		"displayName": "BNB Smart Chain"
	},
	"gnosis": {
		"coingeckoNetworkId": "xdai",
		"displayName": "Gnosis"
	},
	"dev0": {
		"coingeckoNetworkId": "ethereum",
		"dockerHost": "localhost",
		"displayName": "Dev0"
	},
	"dev1": {
		"coingeckoNetworkId": "ethereum",
		"dockerHost": "localhost",
		"displayName": "Dev1"
	},
	"dev2": {
		"coingeckoNetworkId": "ethereum",
		"dataUnionJoinServerUrl": ":5555",
		"displayName": "Dev2",
		"dockerHost": "localhost",
		"marketplaceChains": [
			"dev2"
		],
		"networkSubgraphUrl": ":8800/subgraphs/name/streamr-dev/network-subgraphs",
		"streamIndexerUrl": ":4001/api",
		"storageNodes": [
			{
				"name": "Local (broker-node-storage-1)",
				"address": "0xde1112f631486CfC759A50196853011528bC5FA0"
			}
		],
		"dataunionGraphNames": [
			{
				"chainId": 31337,
				"name": "streamr-dev/dataunion"
			}
		]
	},
	"polygonAmoy": {
		"dataUnionJoinServerUrl": "https://join.dataunions.org/",
		"displayName": "Amoy",
		"marketplaceChains": [
			"polygonAmoy"
		],
		"slug": "amoy",
		"storageNodes": [
			{
				"name": "Streamr Germany",
				"address": "0x31546eEA76F2B2b3C5cC06B1c93601dc35c9D916"
			}
		],
		"dataunionGraphNames": [
			{
				"chainId": 80002,
				"name": "samt1803/dataunion-subgraphs"
			}
		]
	},
	"polygon": {
		"marketplaceChains": [
			"gnosis",
			"polygon"
		],
		"networkSubgraphUrl": "https://gateway-arbitrum.network.thegraph.com/api/8bcbd55cdd1369cadb0bb813d9817776/subgraphs/id/EGWFdhhiWypDuz22Uy7b3F69E9MEkyfU9iAQMttkH5Rj",
		"streamIndexerUrl": "https://stream-metrics.streamr.network/api",
		"storageNodes": [
			{
				"name": "Streamr Germany",
				"address": "0x31546eEA76F2B2b3C5cC06B1c93601dc35c9D916"
			},
			{
				"name": "LogStore Decentralized Storage",
				"address": "0x17f98084757a75add72bf6c5b5a6f69008c28a57",
				"thirdPartyUrl": "https://docs.usher.so/logstore/network/specifics/overview#streamr-storage-provider"
			}
		],
		"dataunionGraphNames": [
			{
				"chainId": 100,
				"name": "dataunions/data-unions-gnosis"
			},
			{
				"chainId": 137,
				"name": "dataunions/data-unions-polygon"
			}
		]
	}
}