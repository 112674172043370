import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { useCurrentChainKey } from '~/utils/chains';
import isPreventable from '~/utils/isPreventable';
import { Route as R, routeOptions } from '~/utils/routes';
export default function ProjectLinkTabs({ projectId, disabled = false, }) {
    const { pathname } = useLocation();
    const chainKey = useCurrentChainKey();
    if (!projectId || disabled) {
        return (React.createElement(Tabs, null,
            React.createElement(Tab, { id: "overview", tag: Link, selected: true, to: pathname, onClick: (e) => {
                    if (isPreventable(e)) {
                        e.preventDefault();
                    }
                } }, "Project overview"),
            React.createElement(Tab, { id: "connect", disabled: true }, "Connect"),
            React.createElement(Tab, { id: "liveData", disabled: true }, "Live data")));
    }
    return (React.createElement(Tabs, null,
        React.createElement(Tab, { id: "overview", tag: Link, to: R.projectOverview(projectId, routeOptions(chainKey)), selected: pathname.startsWith(R.projectOverview(projectId)) }, "Project overview"),
        React.createElement(Tab, { id: "connect", tag: Link, to: R.projectConnect(projectId, routeOptions(chainKey)), selected: pathname.startsWith(R.projectConnect(projectId)) }, "Connect"),
        React.createElement(Tab, { id: "liveData", tag: Link, to: R.projectLiveData(projectId, routeOptions(chainKey)), selected: pathname.startsWith(R.projectLiveData(projectId)) }, "Live data")));
}
