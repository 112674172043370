import queryString from 'query-string';
import { defaultChainKey } from '~/consts';
import { getChainSlug } from './chains';
export function routeOptions(chainIdOrChainKey, search = {}, hash = '') {
    return {
        search: {
            ...search,
            chain: getChainSlug(chainIdOrChainKey),
        },
        hash,
    };
}
function withSuffix(pathname, options = {}) {
    const { hash = '' } = options;
    const { chain, ...search } = options.search || {};
    const qs = options.search
        ? queryString.stringify(chain === defaultChainKey
            ? search
            : {
                ...search,
                chain,
            })
        : '';
    return `${pathname}${qs && `?${qs}`}${hash && `#${hash}`}`;
}
function encode(uriComponent) {
    if (/:\w+/.test(uriComponent)) {
        return uriComponent;
    }
    return encodeURIComponent(uriComponent);
}
export const Route = {
    allowanceInfo() {
        return 'https://tokenallowance.io';
    },
    blog() {
        return 'https://blog.streamr.network/';
    },
    contactGeneral() {
        return 'mailto:contact@streamr.com';
    },
    contactJobs() {
        return 'mailto:jobs@streamr.com';
    },
    contactLabs() {
        return 'mailto:labs@streamr.com';
    },
    contactMedia() {
        return 'mailto:media@streamr.com';
    },
    createOperator(options) {
        return withSuffix('/hub/network/operators/new', options);
    },
    docs(pathname) {
        return `https://docs.streamr.network${(pathname ?? '/')}`;
    },
    error(options) {
        return withSuffix('/error', options);
    },
    giveFeedback() {
        return 'https://github.com/streamr-dev/streamr-platform/issues';
    },
    hub(options) {
        return withSuffix('/hub', options);
    },
    network(options) {
        return withSuffix('/hub/network', options);
    },
    networkExplorer() {
        return 'https://streamr.network/network-explorer';
    },
    networkOverview(options) {
        return withSuffix('/hub/network/overview', options);
    },
    operator(id, options) {
        return withSuffix(`/hub/network/operators/${encode(id)}`, options);
    },
    operators(options) {
        return withSuffix('/hub/network/operators', options);
    },
    privacyPolicy() {
        return 'https://s3.amazonaws.com/streamr-public/streamr-privacy-policy.pdf';
    },
    project(id, options) {
        return withSuffix(`/hub/projects/${encode(id)}`, options);
    },
    projectConnect(id, options) {
        return withSuffix(`/hub/projects/${encode(id)}/connect`, options);
    },
    projectEdit(id, options) {
        return withSuffix(`/hub/projects/${encode(id)}/edit`, options);
    },
    projectLiveData(id, options) {
        return withSuffix(`/hub/projects/${encode(id)}/live-data`, options);
    },
    projectOverview(id, options) {
        return withSuffix(`/hub/projects/${encode(id)}/overview`, options);
    },
    projects(options) {
        return withSuffix('/hub/projects', options);
    },
    publisherTerms() {
        return 'https://s3.amazonaws.com/streamr-public/streamr-data-provider-agreement.pdf';
    },
    resetAllowanceInfo() {
        return 'https://github.com/ethereum/EIPs/issues/20#issuecomment-263524729';
    },
    root() {
        return '/';
    },
    siteAbout() {
        return '/about';
    },
    siteDataToken() {
        return '/discover/data-token';
    },
    siteDataUnions() {
        return '/discover/data-unions';
    },
    siteDesign() {
        return '/design';
    },
    siteEcosystem() {
        return '/ecosystem';
    },
    siteFund() {
        return '/fund';
    },
    siteMarketplace() {
        return '/discover/marketplace';
    },
    siteNetwork() {
        return '/discover/network';
    },
    sitePapers() {
        return '/papers';
    },
    siteRoadmap() {
        return '/roadmap';
    },
    siteTokenMigration() {
        return '/token-migration';
    },
    stream(id, options) {
        return withSuffix(`/hub/streams/${encode(id)}`, options);
    },
    streamConnect(id, options) {
        return withSuffix(`/hub/streams/${encode(id)}/connect`, options);
    },
    streamLiveData(id, options) {
        return withSuffix(`/hub/streams/${encode(id)}/live-data`, options);
    },
    streamOverview(id, options) {
        return withSuffix(`/hub/streams/${encode(id)}/overview`, options);
    },
    streams(options) {
        return withSuffix(`/hub/streams`, options);
    },
    sponsorship(id, options) {
        return withSuffix(`/hub/network/sponsorships/${encode(id)}`, options);
    },
    sponsorships(options) {
        return withSuffix('/hub/network/sponsorships', options);
    },
    tos() {
        return 'https://s3.amazonaws.com/streamr-public/streamr-terms-of-use.pdf';
    },
};
