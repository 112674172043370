import React, { useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS, MEDIUM, REGULAR } from '~/shared/utils/styled';
import { useCurrentChainKey } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { NavLink, NavbarLinkMobile } from './Nav.styles';
import { NetworkNavItems, isNetworkTabActive } from './NetworkDropdown';
export function NetworkAccordion() {
    const { pathname } = useLocation();
    const [isOpen, toggle] = useReducer((x) => !x, false);
    const chainKey = useCurrentChainKey();
    return (React.createElement(React.Fragment, null,
        React.createElement(NavbarLinkMobile, { highlight: isNetworkTabActive(pathname), "$bottomBorder": !isOpen },
            React.createElement(NavLink, { as: Link, to: R.networkOverview(routeOptions(chainKey)), onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggle();
                } },
                React.createElement(Wings, null,
                    React.createElement("div", null, "Network"),
                    React.createElement("div", null,
                        React.createElement(CaretDownIcon, { "$flipped": isOpen }))))),
        isOpen && (React.createElement(Menu, null, NetworkNavItems.map((networkNavElement) => {
            const { title, subtitle, linkFn, ...rest } = networkNavElement;
            return (React.createElement(NetworkMobileLink, { ...rest, to: linkFn(routeOptions(chainKey)), key: title },
                React.createElement(NetworkNavElement, null,
                    React.createElement(Title, null, title),
                    React.createElement(Subtitle, null, subtitle))));
        })))));
}
const NetworkNavElement = styled.div.withConfig({ displayName: "NetworkNavElement", componentId: "sc-19ewe4g" }) `
    display: flex;
    flex-direction: column;
    text-transform: none;
    line-height: 24px;
`;
const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-15my2kk" }) `
    font-size: 16px;
    font-weight: ${MEDIUM};
    color: ${COLORS.black};
    margin: 0;
    letter-spacing: initial;
`;
const Subtitle = styled.p.withConfig({ displayName: "Subtitle", componentId: "sc-iyyr3w" }) `
    font-size: 16px;
    font-weight: ${REGULAR};
    color: ${COLORS.primaryLight};
    margin: 0;
    max-width: 350px;
    white-space: pre-line;
    letter-spacing: initial;
`;
const NetworkMobileLink = styled(Link).withConfig({ displayName: "NetworkMobileLink", componentId: "sc-16r7z00" }) `
    padding: 20px 0;
    display: block;
`;
const Menu = styled.div.withConfig({ displayName: "Menu", componentId: "sc-1gizb9b" }) `
    margin-top: -20px;
`;
function getCaretDownIconAttrs() {
    return {
        name: 'caretDown',
    };
}
const CaretDownIcon = styled(SvgIcon).attrs(getCaretDownIconAttrs).withConfig({ displayName: "CaretDownIcon", componentId: "sc-1h45794" }) `
    transform: rotate(0deg) translateY(-2%);
    transition: 300ms transform;
    width: 18px;

    ${({ $flipped = false }) => $flipped &&
    css `
            transform: rotate(-180deg) translateY(-2%);
        `}
`;
const Wings = styled.div.withConfig({ displayName: "Wings", componentId: "sc-h24jtu" }) `
    display: flex;
    align-items: center;

    > div:first-child {
        flex-grow: 1;
    }
`;
