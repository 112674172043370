import { produce } from 'immer';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { z } from 'zod';
import { getDataUnion, getDataUnionsOwnedByInChain } from '~/getters/du';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import { Tick as PrestyledTick } from '~/shared/components/Checkbox';
import NetworkIcon from '~/shared/components/NetworkIcon';
import SvgIcon from '~/shared/components/SvgIcon';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS } from '~/shared/utils/styled';
import { getChainDisplayName } from '~/utils/chains';
import { Root as SalePointTokenSelectorRoot } from './SalePointTokenSelector';
export default function SalePointOption({ onSalePointChange, salePoint, children, multiSelect = false, }) {
    const { chainId, enabled, readOnly } = salePoint;
    return (React.createElement(DropdownWrap, { "$open": enabled },
        React.createElement(DropdownToggle, { onClick: () => {
                onSalePointChange?.({
                    ...salePoint,
                    /**
                     * In multi select mode we don't allow to toggle. It's a click-2-enable,
                     * always. We let the parent component decide what to do with the value.
                     */
                    enabled: multiSelect ? !salePoint.enabled : true,
                });
            } },
            multiSelect ? (React.createElement(Tick, { "$checked": enabled, "$disabled": readOnly })) : (React.createElement(RadioCircle, { "$checked": enabled, "$disabled": readOnly })),
            React.createElement(ChainIcon, { chainId: chainId }),
            React.createElement(ToggleText, null, getChainDisplayName(chainId)),
            multiSelect && React.createElement(PlusSymbol, null)),
        React.createElement(DropdownOuter, null,
            React.createElement(DropdownInner, null, children))));
}
export const RadioCircle = styled.div.withConfig({ displayName: "RadioCircle", componentId: "sc-1x2ldql" }) `
    border-radius: 50%;
    border: 2px solid ${COLORS.radioBorder};
    height: 15px;
    transition: 0.5s border-color;
    width: 15px;

    ::before {
        background: ${COLORS.link};
        border-radius: 50%;
        content: '';
        display: block;
        height: 7px;
        opacity: 0;
        transform: translate(2px, 2px) scale(0.5);
        transition: 0.5s;
        transition-property: transform, opacity;
        width: 7px;
    }

    ${({ $disabled = false }) => $disabled &&
    css `
            opacity: 0.5;
        `}

    ${({ $checked }) => $checked &&
    css `
            border-color: ${COLORS.link};
            transition-duration: 0.1s;

            ::before {
                opacity: 1;
                transform: translate(2px, 2px) scale(1);
                transition-duration: 0.1s;
            }
        `}
`;
const DropdownToggle = styled.div.withConfig({ displayName: "DropdownToggle", componentId: "sc-1uc9r46" }) `
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 24px;

    :hover {
        background-color: ${COLORS.secondary};
    }

    > ${RadioCircle} {
        margin-right: 20px;
    }
`;
const DropdownInner = styled.div.withConfig({ displayName: "DropdownInner", componentId: "sc-11fyshv" }) `
    padding: 8px 24px 64px;
    transition: margin-bottom 0.5s ease-in-out;
    margin-bottom: -350%;

    h4 {
        font-weight: 400;
        font-size: 20px;
        margin: 0;
    }

    h4 + p {
        font-size: 16px;
        margin: 16px 0 28px;
        line-height: 1.5em;
    }

    ${SalePointTokenSelectorRoot} {
        margin-bottom: 48px;
    }
`;
const ToggleText = styled.div.withConfig({ displayName: "ToggleText", componentId: "sc-112qiyv" }) `
    flex-grow: 1;
`;
function getPlusSymbolAttrs() {
    return {
        name: 'plus',
    };
}
const PlusSymbol = styled(SvgIcon).attrs(getPlusSymbolAttrs).withConfig({ displayName: "PlusSymbol", componentId: "sc-1hkuv5d" }) `
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
    width: 14px;
`;
const DropdownWrap = styled.div.withConfig({ displayName: "DropdownWrap", componentId: "sc-1uricu7" }) `
    overflow: hidden;
    box-shadow: 0 1px 2px 0 #00000026, 0 0 1px 0 #00000040;
    border-radius: 4px;
    color: ${COLORS.primary};

    & + & {
        margin-top: 24px;
    }

    ${({ $open = false }) => $open &&
    css `
            ${DropdownInner} {
                margin-bottom: 0;
            }

            ${DropdownToggle}:hover {
                background-color: inherit;
            }

            ${PlusSymbol} {
                transform: rotate(45deg);
            }
        `}
`;
const DropdownOuter = styled.div.withConfig({ displayName: "DropdownOuter", componentId: "sc-o12d7f" }) `
    overflow: hidden;
`;
const Tick = styled(PrestyledTick).withConfig({ displayName: "Tick", componentId: "sc-1pe3o71" }) `
    margin: 0 20px 0 0;

    ${({ $disabled = false }) => $disabled &&
    css `
            opacity: 0.3;
        `}
`;
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-1daap6y" }) `
    width: 32px;
    height: 32px;
    margin-right: 12px;
`;
const NamedMetadata = z.object({
    name: z.string(),
});
function isNamedMetadata(metadata) {
    return NamedMetadata.safeParse(metadata).success;
}
export function DataUnionOption({ salePoint, onSalePointChange }) {
    const [dataUnionId, setDataUnionId] = useState('');
    const { chainId } = salePoint;
    const [deployNew, setDeployNew] = useState(true);
    const [dataUnions, setDataUnions] = useState();
    const isLoadingDataUnions = typeof dataUnions === 'undefined';
    const canUseExisting = !!dataUnions?.length;
    const account = useWalletAccount();
    function updateSalePoint(updater) {
        onSalePointChange?.(produce(salePoint, updater));
    }
    const updateSalePointRef = useRef(updateSalePoint);
    updateSalePointRef.current = updateSalePoint;
    useEffect(() => {
        let mounted = true;
        if (!account) {
            return () => { };
        }
        /**
         * Tell the app we're loading the Unions. See `isLoadingDataUnions`.
         */
        setDataUnions(undefined);
        /**
         * Reset the new/existing selection because it's impossible to select
         * exisitng id from `undefined` set above.
         */
        setDeployNew(true);
        updateSalePointRef.current((draft) => {
            draft.beneficiaryAddress = '';
        });
        setTimeout(async () => {
            const result = [];
            try {
                const foundDataUnions = await getDataUnionsOwnedByInChain(account, chainId);
                if (!mounted) {
                    return;
                }
                for (const { id } of foundDataUnions) {
                    try {
                        const dataUnion = await getDataUnion(id, chainId);
                        if (!mounted) {
                            // There's no need to carry on. The result is gonna get discarded.
                            break;
                        }
                        const metadata = await dataUnion.getMetadata();
                        if (!mounted) {
                            // Again, there's no need to carry on. See above.
                            break;
                        }
                        result.push({
                            value: id,
                            label: isNamedMetadata(metadata) ? metadata.name : id,
                        });
                    }
                    catch (e) {
                        console.warn(`Failed to load a Data Union: ${id}`, e);
                    }
                }
            }
            catch (e) {
                console.warn('Failed to load Data Unions', e);
            }
            finally {
                if (mounted) {
                    setDataUnions(result);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [chainId, account]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Deployment"),
        React.createElement("p", null, "You can deploy a new Data Union smart contract, or connect an existing one."),
        React.createElement(DeployList, null,
            React.createElement("li", null,
                React.createElement("button", { type: "button", onClick: () => {
                        setDeployNew(true);
                        updateSalePoint((draft) => {
                            draft.beneficiaryAddress = '';
                        });
                    } },
                    React.createElement(RadioCircle, { "$checked": deployNew }),
                    React.createElement("p", null, "Deploy a new Data Union"))),
            React.createElement("li", null,
                React.createElement("button", { type: "button", disabled: !canUseExisting, onClick: () => {
                        if (!canUseExisting) {
                            return;
                        }
                        setDeployNew(false);
                        updateSalePoint((draft) => {
                            draft.beneficiaryAddress = dataUnionId;
                        });
                    } },
                    React.createElement(RadioCircle, { "$checked": !deployNew, "$disabled": !canUseExisting }),
                    React.createElement("p", null, "Connect an existing Data Union")),
                React.createElement(SelectWrap, null,
                    React.createElement(SelectField2, { placeholder: isLoadingDataUnions
                            ? 'Loading…'
                            : canUseExisting
                                ? 'Select a Data Union'
                                : "You don't have any deployed Data Unions", options: dataUnions || [], disabled: deployNew, fullWidth: true, value: dataUnionId || undefined, isClearable: false, onChange: (newDataUnionId) => {
                            setDataUnionId(newDataUnionId);
                            updateSalePoint((draft) => {
                                draft.beneficiaryAddress = newDataUnionId;
                            });
                        } }))))));
}
const DeployList = styled.ul.withConfig({ displayName: "DeployList", componentId: "sc-10wgl0g" }) `
    background-color: ${COLORS.inputBackground};
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 24px;

    li {
        background: #ffffff;
        border-radius: 4px;
    }

    li > button {
        align-items: center;
        appearance: none;
        background: none;
        border: 0;
        display: flex;
        padding: 24px;
        text-align: left;
        width: 100%;
    }

    li + li {
        margin-top: 16px;
    }

    p {
        flex-grow: 1;
        font-size: 14px;
        margin: 0;
    }

    ${RadioCircle} {
        margin-right: 12px;
    }
`;
const SelectWrap = styled.div.withConfig({ displayName: "SelectWrap", componentId: "sc-tvsugl" }) `
    padding: 0 24px 24px;
`;
