import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AddressTable, AddressType, useSubmitControllerAddressesCallback, useSubmitNodeAddressesCallback, } from '~/components/AddressTable';
import { BehindBlockErrorDisplay } from '~/components/BehindBlockErrorDisplay';
import { ChartPeriodTabs } from '~/components/ChartPeriodTabs';
import { SponsorshipDecimals } from '~/components/Decimals';
import { NetworkHelmet } from '~/components/Helmet';
import { Hint } from '~/components/Hint';
import Layout, { LayoutColumn } from '~/components/Layout';
import NetworkChartDisplay from '~/components/NetworkChartDisplay';
import NetworkPageSegment, { Pad, SegmentGrid, TitleBar, } from '~/components/NetworkPageSegment';
import { Separator } from '~/components/Separator';
import { StatCellContent, StatCellLabel } from '~/components/StatGrid';
import { StreamIdCell } from '~/components/Table';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { getDelegatedAmountForWallet, getDelegationFractionForWallet } from '~/getters';
import { getOperatorStats } from '~/getters/getOperatorStats';
import { useConfigValueFromChain, useInitialBehindIndexError, useLatestBehindBlockError, useRefetchQueryBehindIndexEffect, } from '~/hooks';
import { invalidateActiveOperatorByIdQueries, useOperatorByIdQuery, } from '~/hooks/operators';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import { useInterceptHeartbeats } from '~/hooks/useInterceptHeartbeats';
import { LiveNodesTable } from '~/pages/OperatorPage/LiveNodesTable';
import { OperatorActionBar } from '~/pages/OperatorPage/OperatorActionBar';
import { OperatorChecklist } from '~/pages/OperatorPage/OperatorChecklist';
import { OperatorDetails } from '~/pages/OperatorPage/OperatorDetails';
import { OperatorSummary } from '~/pages/OperatorPage/OperatorSummary';
import { SponsorshipTable } from '~/pages/OperatorPage/SponsorshipTable';
import { UndelegationQueue } from '~/pages/OperatorPage/UndelegationQueue';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import { NoData } from '~/shared/components/NoData';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import SvgIcon from '~/shared/components/SvgIcon';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { NetworkChart } from '~/shared/components/TimeSeriesGraph';
import { formatLongDate, formatShortDate, } from '~/shared/components/TimeSeriesGraph/chartUtils';
import { useWalletAccount } from '~/shared/stores/wallet';
import { LAPTOP, MAX_BODY_WIDTH, TABLET } from '~/shared/utils/styled';
import { useSetBlockDependency } from '~/stores/blockNumberDependencies';
import { ChartPeriod } from '~/types';
import { abbr } from '~/utils';
import { onIndexedBlock } from '~/utils/blocks';
import { toBN, toBigInt, toFloat } from '~/utils/bn';
import { useCurrentChainFullName, useCurrentChainId, useCurrentChainKey, } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { errorToast } from '~/utils/toast';
const defaultChartData = [];
const defaultPersistedNodes = [];
const defaultPersistedControllers = [];
export const OperatorPage = () => {
    const operatorId = useParams().id;
    const operatorQuery = useOperatorByIdQuery(operatorId);
    const operator = operatorQuery.data || null;
    const initialBehindBlockError = useInitialBehindIndexError(operatorQuery, [
        operatorId,
    ]);
    useRefetchQueryBehindIndexEffect(operatorQuery);
    const behindBlockError = useLatestBehindBlockError(operatorQuery);
    const isFetching = operatorQuery.isLoading || operatorQuery.isFetching || !!behindBlockError;
    const walletAddress = useWalletAccount();
    const slashingFraction = useConfigValueFromChain('slashingFraction', (value) => toFloat(value, 18n)) ??
        null;
    const minimumStakeWei = useConfigValueFromChain('minimumStakeWei');
    const isOwner = walletAddress != null &&
        operator != null &&
        walletAddress.toLowerCase() === operator.owner.toLowerCase();
    const isController = walletAddress != null &&
        operator != null &&
        operator.controllers.some((c) => c.address.toLowerCase() === walletAddress.toLowerCase());
    const { symbol: tokenSymbol = 'DATA' } = useSponsorshipTokenInfo() || {};
    const [selectedDataSource, setSelectedDataSource] = useState('cumulativeEarnings');
    const currentChainId = useCurrentChainId();
    const chainKey = useCurrentChainKey();
    const earliestUndelegationTimestamp = operator?.delegations.find((d) => d.delegator.toLowerCase() === walletAddress?.toLowerCase())?.earliestUndelegationTimestamp;
    const [selectedPeriod, setSelectedPeriod] = useState(ChartPeriod.ThreeMonths);
    const chartQuery = useQuery({
        queryKey: [
            'operatorChartQuery',
            currentChainId,
            operatorId,
            selectedPeriod,
            selectedDataSource,
        ],
        queryFn: async () => {
            try {
                if (!operatorId) {
                    return [];
                }
                return await getOperatorStats(currentChainId, operatorId, selectedPeriod, selectedDataSource, { force: true, ignoreToday: false });
            }
            catch (_) {
                errorToast({ title: 'Could not load operator chart data' });
                return [];
            }
        },
    });
    const { data: chartData = defaultChartData } = chartQuery;
    const myDelegationAmount = useMemo(() => {
        if (!walletAddress || !operator) {
            return 0n;
        }
        return getDelegatedAmountForWallet(walletAddress, operator);
    }, [operator, walletAddress]);
    const myDelegationPercentage = useMemo(() => {
        if (!walletAddress || !operator) {
            return toBN(0);
        }
        return getDelegationFractionForWallet(walletAddress, operator).multipliedBy(100);
    }, [walletAddress, operator]);
    const chartLabel = selectedDataSource === 'cumulativeEarnings'
        ? 'Cumulative earnings'
        : 'Total stake';
    const { nodes: persistedNodes = defaultPersistedNodes } = operator || {};
    const [nodes, setNodes] = useState(persistedNodes);
    useEffect(() => void setNodes(persistedNodes), [persistedNodes]);
    const [saveNodeAddresses, isSavingNodeAddresses] = useSubmitNodeAddressesCallback();
    const { controllers: persistedControllers = defaultPersistedControllers } = operator || {};
    const [controllers, setControllers] = useState(persistedControllers);
    useEffect(() => void setControllers(persistedControllers), [persistedControllers]);
    const [saveControllers, isSavingControllerAddresses] = useSubmitControllerAddressesCallback();
    const setBlockDependency = useSetBlockDependency();
    const heartbeats = useInterceptHeartbeats(operator?.id);
    const saveNodeAddressesCb = useCallback(async (addresses) => {
        if (!operatorId) {
            return;
        }
        const chainId = currentChainId;
        try {
            await saveNodeAddresses(chainId, operatorId, addresses, {
                onSuccess(blockNumber) {
                    setNodes((current) => {
                        const newAddresses = [];
                        current.forEach((node) => {
                            if (node.enabled) {
                                newAddresses.push({
                                    ...node,
                                    persisted: true,
                                });
                            }
                        });
                        return newAddresses;
                    });
                    setBlockDependency(chainId, blockNumber, [
                        'operatorNodes',
                        operatorId,
                    ]);
                    onIndexedBlock(chainId, blockNumber, () => {
                        invalidateActiveOperatorByIdQueries(chainId, operatorId);
                    });
                },
                onReject() {
                    // Undo changes
                    setNodes((current) => current
                        .filter((val) => val.persisted === true)
                        .map((n) => ({
                        ...n,
                        enabled: true,
                    })));
                },
                onError() {
                    errorToast({
                        title: 'Failed to save the new node addresses',
                    });
                },
            });
        }
        catch (_) { }
    }, [currentChainId, operatorId, saveNodeAddresses, setBlockDependency]);
    const saveControllerAddressesCb = useCallback(async (address, isNew) => {
        if (!operatorId) {
            return;
        }
        const chainId = currentChainId;
        try {
            await saveControllers(chainId, operatorId, address, isNew, {
                onSuccess(blockNumber) {
                    setControllers((current) => {
                        const newAddresses = [];
                        current.forEach((node) => {
                            if (node.enabled) {
                                newAddresses.push({
                                    ...node,
                                    persisted: true,
                                });
                            }
                        });
                        return newAddresses;
                    });
                    setBlockDependency(chainId, blockNumber, [
                        'operatorNodes',
                        operatorId,
                    ]);
                    onIndexedBlock(chainId, blockNumber, () => {
                        invalidateActiveOperatorByIdQueries(chainId, operatorId);
                    });
                },
                onReject() {
                    // Undo changes
                    setControllers((current) => current
                        .filter((val) => val.persisted === true)
                        .map((n) => ({
                        ...n,
                        enabled: true,
                    })));
                },
                onError() {
                    errorToast({
                        title: 'Failed to save the new controllers',
                    });
                },
            });
        }
        catch (_) { }
    }, [currentChainId, operatorId, saveControllers, setBlockDependency]);
    const fullChainName = useCurrentChainFullName();
    const placeholder = behindBlockError ? (React.createElement(BehindBlockErrorDisplay, { latest: behindBlockError, initial: initialBehindBlockError || undefined })) : !isFetching ? (React.createElement(NoData, { firstLine: `Operator not found on the ${fullChainName} chain.` })) : null;
    return (React.createElement(Layout, null,
        React.createElement(NetworkHelmet, { title: "Operator" }),
        React.createElement(LoadingIndicator, { loading: isFetching }),
        !!operator && (React.createElement(React.Fragment, null,
            React.createElement(OperatorActionBar, { operator: operator }),
            React.createElement(OperatorDetails, { operator: operator }),
            React.createElement(OperatorSummary, { operator: operator }),
            isOwner && (React.createElement(OperatorVersionNotice, { version: operator.contractVersion })))),
        React.createElement(LayoutColumn, null, operator == null ? (placeholder) : (React.createElement(SegmentGrid, null,
            React.createElement(ChartGrid, null,
                React.createElement(NetworkPageSegment, { title: "Overview charts" },
                    React.createElement(Pad, null,
                        React.createElement(NetworkChartDisplay, { periodTabs: React.createElement(ChartPeriodTabs, { value: selectedPeriod, onChange: setSelectedPeriod }), sourceTabs: React.createElement(Tabs, { selection: selectedDataSource, onSelectionChange: (dataSource) => {
                                    if (dataSource !== 'totalValue' &&
                                        dataSource !==
                                            'cumulativeEarnings') {
                                        return;
                                    }
                                    setSelectedDataSource(dataSource);
                                } },
                                React.createElement(Tab, { id: "cumulativeEarnings" }, "Cumulative earnings"),
                                React.createElement(Tab, { id: "totalValue" }, "Total stake")) },
                            React.createElement(NetworkChart, { isLoading: chartQuery.isLoading ||
                                    chartQuery.isFetching, tooltipValuePrefix: chartLabel, graphData: chartData, xAxisDisplayFormatter: formatShortDate, yAxisAxisDisplayFormatter: abbr, tooltipLabelFormatter: formatLongDate, tooltipValueFormatter: (value) => tooltipValueFormatter(value, tokenSymbol) })))),
                React.createElement("div", null,
                    React.createElement(SegmentGrid, null,
                        React.createElement(NetworkPageSegment, { title: isOwner ? 'My stake' : 'My delegation' }, walletAddress ? (React.createElement(React.Fragment, null,
                            React.createElement(DelegationCell, null,
                                React.createElement(Pad, null,
                                    React.createElement(StatCellLabel, null,
                                        "Current stake",
                                        operator.contractVersion >
                                            0 &&
                                            earliestUndelegationTimestamp !=
                                                null &&
                                            earliestUndelegationTimestamp *
                                                1000 >
                                                Date.now() && (React.createElement(Tooltip, { content: React.createElement(React.Fragment, null,
                                                "You can not undelegate because your minimum delegation period is still active. It will expire on",
                                                ' ',
                                                moment(earliestUndelegationTimestamp *
                                                    1000).format('YYYY-MM-DD HH:mm'),
                                                ".") },
                                            React.createElement(TooltipIconWrap, { className: "ml-1", "$color": "#ADADAD", "$svgSize": {
                                                    width: '18px',
                                                    height: '18px',
                                                } },
                                                React.createElement(SvgIcon, { name: "lockClosed" }))))),
                                    React.createElement(StatCellContent, null,
                                        React.createElement(SponsorshipDecimals, { abbr: true, amount: myDelegationAmount })))),
                            React.createElement(Separator, null),
                            React.createElement(DelegationCell, null,
                                React.createElement(Pad, null,
                                    React.createElement(StatCellLabel, null, "Share of Operator's total stake"),
                                    React.createElement(StatCellContent, null,
                                        myDelegationPercentage.toFixed(0),
                                        "%"))))) : (React.createElement(Pad, null, "Connect your wallet to show your delegation."))),
                        React.createElement(NetworkPageSegment, { title: "Operator status" },
                            React.createElement(OperatorChecklist, { operatorId: operatorId }))))),
            React.createElement(NetworkPageSegment, { foot: true, title: React.createElement(TitleBar, { label: operator.stakes.length }, "Sponsorships") },
                React.createElement(SponsorshipTable, { operator: operator, isController: isController })),
            React.createElement(NetworkPageSegment, { title: "Undelegation queue" },
                React.createElement(UndelegationQueue, { operatorId: operatorId })),
            React.createElement(NetworkPageSegment, { foot: true, title: "Slashing history" },
                React.createElement(SlashingHistoryTableContainer, null,
                    React.createElement(ScrollTable, { elements: operator.slashingEvents.sort((a, b) => b.date - a.date), columns: [
                            {
                                displayName: 'Stream ID',
                                valueMapper: ({ streamId }) => (React.createElement(StreamIdCell, { streamId: streamId })),
                                align: 'start',
                                isSticky: true,
                                key: 'id',
                            },
                            {
                                displayName: 'Date',
                                valueMapper: (element) => moment(element.date * 1000).format('YYYY-MM-DD HH:mm'),
                                align: 'start',
                                isSticky: false,
                                key: 'date',
                            },
                            {
                                displayName: 'Slashed',
                                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.amount })),
                                align: 'start',
                                isSticky: false,
                                key: 'slashed',
                            },
                            {
                                displayName: 'Reason',
                                valueMapper: (element) => {
                                    if (slashingFraction == null ||
                                        minimumStakeWei == null) {
                                        return '';
                                    }
                                    if (element.amount <
                                        toBigInt(slashingFraction.multipliedBy(toBN(minimumStakeWei)))) {
                                        return 'False flag';
                                    }
                                    return 'Normal slashing';
                                },
                                align: 'start',
                                isSticky: false,
                                key: 'reason',
                            },
                        ], linkMapper: ({ sponsorshipId: id }) => R.sponsorship(id, routeOptions(chainKey)) }))),
            isController && (React.createElement(NetworkPageSegment, { title: React.createElement(NodeAddressHeader, null,
                    React.createElement("span", null, "Operator's node addresses"),
                    ' ',
                    React.createElement("div", null,
                        React.createElement(Hint, null,
                            React.createElement("p", null, "Your nodes need wallets for smart contract interactions. Generate Ethereum wallets using your tool of choice, add the private key to your node's config file, and add the corresponding address here. You can run multiple nodes with the same address/private\u00A0key."),
                            React.createElement("p", null, "Each node address should be supplied with some POL on Polygon chain for\u00A0gas.")))) },
                React.createElement(AddressTable, { type: AddressType.Node, busy: isSavingNodeAddresses, value: nodes, onChange: setNodes, onAddAddress: async (address) => {
                        const addresses = [
                            ...nodes.map((n) => n.address),
                            address,
                        ];
                        await saveNodeAddressesCb(addresses);
                    }, onRemoveAddress: async (address) => {
                        const addresses = nodes
                            .filter((n) => n.address !== address)
                            .map((n) => n.address);
                        await saveNodeAddressesCb(addresses);
                    } }))),
            isController && (React.createElement(NetworkPageSegment, { title: React.createElement(NodeAddressHeader, null,
                    React.createElement("span", null, "Staking agents"),
                    ' ',
                    React.createElement("div", null,
                        React.createElement(Hint, null,
                            React.createElement("p", null, "You can authorize certain addresses to stake and unstake your Operator. These addresses are not allowed to withdraw funds out of the Operator. Such addresses are useful for automation or for using a 'hotter' wallet for convenience when managing your day-to-day staking operations.")))) },
                React.createElement(AddressTable, { type: AddressType.Automation, busy: isSavingControllerAddresses, disableEditing: !isOwner, value: controllers.filter((c) => c.address.toLowerCase() !==
                        operator.owner.toLowerCase()), onChange: setControllers, onAddAddress: (address) => {
                        saveControllerAddressesCb(address, true);
                    }, onRemoveAddress: (address) => {
                        saveControllerAddressesCb(address, false);
                    } }))),
            isController && (React.createElement(NetworkPageSegment, { title: React.createElement(TitleBar, { label: Object.keys(heartbeats).length }, "Live nodes") },
                React.createElement(LiveNodesTable, { heartbeats: heartbeats }))))))));
};
function tooltipValueFormatter(value, tokenSymbol) {
    return `${abbr(value)} ${tokenSymbol}`;
}
const ChartGrid = styled(SegmentGrid).withConfig({ displayName: "ChartGrid", componentId: "sc-z36y26" }) `
    grid-template-columns: minmax(0, 1fr);

    @media ${LAPTOP} {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
`;
const DelegationCell = styled.div.withConfig({ displayName: "DelegationCell", componentId: "sc-qvn3mt" }) `
    ${Pad} {
        padding-bottom: 12px;
        padding-top: 12px;
    }

    @media ${TABLET} {
        ${StatCellContent} {
            font-size: 24px;
            line-height: 40px;
        }
    }
`;
const NodeAddressHeader = styled.h2.withConfig({ displayName: "NodeAddressHeader", componentId: "sc-1d81gc9" }) `
    display: flex;
    align-items: center;
`;
const NoticeBar = styled.div.withConfig({ displayName: "NoticeBar", componentId: "sc-1npqfx7" }) `
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff4ee;
    font-size: 14px;
    line-height: 20px;
    color: #323232;
    padding: 8px 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025);
`;
const NoticeWrap = styled.div.withConfig({ displayName: "NoticeWrap", componentId: "sc-1dgrgm8" }) `
    display: grid;
    grid-template-columns: 18px 1fr;
    gap: 8px;
    align-items: center;
    margin: 0 auto;
    max-width: ${MAX_BODY_WIDTH}px;
    padding: 0 24px;
    width: 100%;

    @media (min-width: ${MAX_BODY_WIDTH + 48}px) {
        padding: 0;
    }
`;
const SlashingHistoryTableContainer = styled.div.withConfig({ displayName: "SlashingHistoryTableContainer", componentId: "sc-1t1lti" }) `
    max-height: none;

    @media ${LAPTOP} {
        max-height: 575px;
    }
`;
function OperatorVersionNotice(params) {
    const { version } = params;
    let notice;
    if (version === 0) {
        notice = (React.createElement(React.Fragment, null,
            "Your Operator smart contract is outdated.",
            ' ',
            React.createElement("a", { href: R.docs('/help/operator-faq#migrating-from-streamr-10-testnet-to-streamr-10'), rel: "noopener noreferrer", target: "_blank" }, "Click here"),
            ' ',
            "to learn how to migrate to the latest version."));
    }
    if (version === 1) {
        notice = (React.createElement(React.Fragment, null,
            "You have a version of the Operator smart contract where withdrawals and undelegations are broken.",
            React.createElement("br", null),
            "The tokens within the Operator can be recovered. Please see",
            ' ',
            React.createElement("a", { href: "https://discord.com/channels/801574432350928907/1169745015363338300/1214980272123019264", target: "_blank", rel: "noopener noreferrer" }, "these instructions"),
            ' ',
            "or get in touch with the team."));
    }
    if (!notice) {
        return null;
    }
    return (React.createElement(NoticeBar, null,
        React.createElement(NoticeWrap, null,
            React.createElement(TooltipIconWrap, { "$color": "#ff5c00" },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error" })),
            React.createElement("div", null, notice))));
}
